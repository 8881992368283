<template>
  <div class="iotProduct">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="getStoreList"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="add()"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          label="存储名称："
          placeholder="请输入名称"
          :clearable="true"
          v-model="searchParam.name"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="edit(scope.row)" />
        <v-button text="删除" type="text" @click="remove(scope.row)" />
      </template>
    </v-list>
    <v-dialog v-model="show" width="50%" @confirm="Submit">
      <form-panel
        :label-position="'left'"
        ref="formPanel"
        :form="form"
        :hasHeader="false"
        class="form-panel"
      >
        <el-form-item
          label="所属数据库"
          prop="authSpaceIds"
          :rules="[
            { required: false, message: '请选择数据库', trigger: 'blur' },
          ]"
        >
          <v-cascader
            placeholder="请选择数据库"
            :options="sectionOps"
            @change="sectionChange"
          />
        </el-form-item>
        <el-form-item
          label="数据源名称"
          :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]"
          prop="name"
        >
          <v-input
            placeholder="请输入数据源名称"
            v-model="form.tableName"
          ></v-input
        ></el-form-item>
        <el-form-item
          label="表名"
          :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]"
          prop="tableName"
        >
          <v-input placeholder="请输入表名" v-model="form.name"></v-input
        ></el-form-item>
      </form-panel>
    </v-dialog>
  </div>
</template>

<script>
import { checkPrimeSync } from "crypto";
import {
  getStoreList,
  getStoreSubmit,
  getSelectList,
  getStoreDetail,
  removeStore,
} from "./api.js";
export default {
  data() {
    return {
      show: false,
      getStoreList,
      form: {
        tableName: "",
        name: "",
      },
      searchParam: {
        curPage: 1,
        pageSize: 10,
        name: undefined,
      },
      headers: [
        {
          prop: "name",
          label: "存储名称",
        },
        {
          prop: "relatedModelAssetName",
          label: "数据资产",
        },
        {
          prop: "updateTime",
          label: "创建时间",
        },
      ],
      sectionOps: [],
      type: undefined,
    };
  },
  created() {
    this.getSelectList();
  },

  methods: {
    add() {
      this.show = true;
      this.form.tableName = "";
      this.form.name = "";
    },
    edit(row) {
      this.show = true;
      this.form.tableName = JSON.parse(row.config).tableName;
      this.form.name = row.name;
    },
    remove(row) {
      const params = {
        id: row.id,
      };
      this.$axios
        .post(`${removeStore}?id=${row.id}`, params, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
            this.$message.success("删除成功");
            this.$refs.list.search();
          }
        });
    },

    Submit() {
      const result = this.$refs.formPanel.validate();
      if (result === true) {
        let config = { tableName: this.form.name };
        const params = {
          name: this.form.tableName,
          type: this.type,
          config: JSON.stringify(config),
        };
        console.log(params);
        this.$axios
          .post(`${getStoreSubmit}`, params, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            console.log(res);
            if (res.code === 200) {
              this.$message.success("添加成功");
              this.show = false;
              this.$refs.list.search();
            }
          });
      }
    },
    sectionChange(value) {
      this.type = value[1];
    },
    getSelectList() {
      this.$axios.get(`${getSelectList}`).then((res) => {
        if (res.code === 200) {
          res.data.forEach((item, index) => {
            var data = {};
            data.label = item.parentName;
            data.value = item.parentName;
            data.children = [];
            data.disabled = true;
            if (item.parentName === "关系型数据库") {
              data.disabled = false;
            }
            item.childrens.forEach((items, indexs) => {
              var ary = {};
              ary.label = items.name;
              ary.value = items.type;
              data.children.push(ary);
            });
            this.sectionOps.push(data);
            console.log(this.sectionOps);
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.iotProduct {
  box-sizing: border-box;
  height: 100%;
}
.form-panel {
  padding: 0 30px;
}
</style>
